<template>
  <div class="home">
    <HeaderCom @changeTemperature="targetTemperature"/>
		<div class="home_info">
			<div class="home_info_operation">
				<div class="tab">
					<div
						v-for="item in tabsList"
						:key="item.id"
						:class="['tab_item', { 'tab_item_active': tabId === item.id }]"
						@click="changeTabId(item.id)"
					>{{ item.name }}</div>
				</div>
				<div class="operation">
					<button :class="['operation_button', { emptyDisabled }]" @click="!emptyDisabled && changeKey(1)">清空在线设备</button>
					<button :class="['operation_button', { emptyDisabled2 }]" @click="!emptyDisabled2 && changeKey(2)">
						<img src="../assets/export.png" alt="">
						<span>导出并删除异常设备</span>
					</button>
					<button class="operation_button" @click="changeKey(3)">
						<img src="../assets/add.png" alt="">
						<span>添加设备</span>
					</button>
				</div>
			</div>

			<div class="home_info_list" v-loading="listLoading">
				<div class="list_item" v-for="(i, n) in list" :key="n">
					<div class="code">SN：{{ i.equipSn }}</div>
					<div :class="['info', { 'abnormal': i.abnormal }]">
						<span v-if="tabId === 1" class="num">{{ i.temperature }}</span>
						<span v-if="tabId === 1" class="unit">℃</span>
						<span v-else class="num">{{ i == 2 ? "离线" : "正常" }}</span>
					</div>
				</div>
			</div>

		</div>
		<el-dialog
			:title="titleObj[titleKey]"
			:visible.sync="showDialog"
			width="488px"
			append-to-body
			custom-class="dialog_equipment"
			:before-close="handleClose">
			<div class="dialog_content" v-show="titleKey === 3">
				<el-form :model="form" :rules="rules" ref="formHome" label-width="90px" label-position="left">
					<el-form-item label="批量导入" prop="fileList">
						<div class="file_info">
							<div class="file_info_list">
								<div class="operation" @click="operation" v-show="!form.fileList.length">
									<div class="text">上传文件</div>
									<div class="line"></div>
									<img src="../assets/upload.png" alt="">
								</div>
								<div class="list" v-for="(item, i) in form.fileList" :key="i">
									<div class="name">{{ item.name }}</div>
									<i class="el-icon-error" @click="deleteFile(i)"></i>
								</div>
							</div>
							<div class="file_info_status" v-show="form.fileList.length">
								<i class="el-icon-success"></i>
								上传成功！
							</div>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleClose">取消</el-button>
				<el-button type="primary" @click="confirm" :loading="loading">确定</el-button>
			</span>
		</el-dialog>
		<input ref="fileElement" @change="getFile" type="file" hidden v-show="false">
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCom from "@/components/header.vue";
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver';

import { geDeviceListApi, deleteDeviceApi, importDevice, getTemperature } from "@/api"; 

export default {
  name: "HomeView",
  components: {
		HeaderCom
  },
  data() {
		const validateFile = (rule, value, callback) => {
			if ((!value || !value.length) && !this.form.fileList.length) {
				callback(new Error('请上传文件'));
			} else {
				callback();
			}
		};
    return {
			tabId: 1,
			tabsList: [
				{ name: "温度贴", id: 1 },
				// { name: "中继器", id: 2 }
			],
			showDialog: false,
			form: {
				fileList: []
			}, // 添加设备
			rules: {
				fileList: [{ required: true, validator: validateFile, trigger: 'blur' }]
			},
			file: null,
			titleObj: {
				"1": "确认清空全部在线设备？",
				"2": "确认导出并删除异常设备？",
				"3": "添加设备"
			},
			titleKey: 1,
			list: [], // 列表展示
			equipSnList: [],
			loading: false,
			listLoading: false,
			temperature: { target: 0, deviation: 0 },
			time: null // 定时器
    };
	},
	computed: {
		emptyDisabled() {
			return !this.list.length; 
		},
		emptyDisabled2() {
			return !this.list.some(e => e.abnormal);
		}
	},
	methods: {
		// 改变tab
		changeTabId(id) {
			if (this.tabId !== id) {
				this.tabId = id;
			}
		},
		// 关闭弹窗
		handleClose() {
			this.$refs["formHome"].resetFields();
			this.form.fileList = [];
			this.file = null;
			this.showDialog = false;
			this.loading = false;
		},
		messageApi(message) {
			this.$message({
				type: "success",
				message
			});
		},
		confirm() {
			if (this.titleKey === 1) {
				this.EmptyDevice();
			} else if (this.titleKey === 2) {
				this.ExportDevice();
			} else {
				this.AddDevice();
			}
		},
		// 上传文件
		operation() {
			this.$refs.fileElement.click();
		},
		getFile(event) {
			const file = event.target.files[0];
      if (!file) {
        return;
      }
      const fileName = file.name;
			const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      if (extension !== 'xls' && extension !== 'xlsx') {
				this.$message({
					type: "error",
					message: "只能上传excel表格"
				});
      } else {
				this.form.fileList.push({
					name: fileName
				});
				this.$refs.formHome.validateField(['fileList']); // 校验
				this.file = file;
      }
			this.$refs.fileElement.value = null;
		},
		deleteFile(i) {
			this.form.fileList.splice(i, 1);
		},
		changeKey(n) {
			this.titleKey = n;
			this.showDialog = true;
		},
		// 清空在线设备
		async EmptyDevice(bool) {
			this.loading = true;
			const { status } = await deleteDeviceApi({
				ids: bool ? this.list.filter(e => e.abnormal).map(e => e.id) : this.equipSnList.map(e => e.id)
			});
			if (status && status === 1) {
				this.list = [];
				clearTimeout(this.time); // 清除定时器
				this.messageApi("删除成功！");
			}
			if (bool) {
				this.getData();
				return;
			}
			setTimeout(() => {
				this.handleClose();
			}, 300);
		},
		// 导出异常设备
		ExportDevice() {
			this.loading = true;
			const arr = this.list.filter(e => e.abnormal).map(e => {
				return {
					DeviceName: e.equipSn
				}
			});
			if (arr && arr.length) this.downloadXLSX(arr);
			setTimeout(() => {
				this.handleClose();
			}, 300);
		},
		// 添加设备
		AddDevice() {
			this.$refs["formHome"].validate(async (valid) => {
				if (valid) {
					this.loading = true;
					const formData = new FormData();
					formData.append('file', this.file);
					const { status } = await importDevice(formData);
					if (status && status === 1) {
						setTimeout(async () => {
							clearTimeout(this.time); // 清除定时器
							this.getData();
							this.handleClose();
							this.messageApi("添加成功！");
						}, 500);
					}
					// 处理文件上传逻辑
				} else {
					return false;
				}
			});
		},
		downloadXLSX(arr) {
      // 创建工作簿
      const wb = XLSX.utils.book_new();
      // 创建工作表
      const ws = XLSX.utils.json_to_sheet(arr);
      // 将工作表添加到工作簿
      XLSX.utils.book_append_sheet(wb, ws, '温度贴');
      // 生成xlsx文件
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
      // 字符串转ArrayBuffer
      function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
			}
			const tab = this.tabsList.find(e => e.id === this.tabId);
      // 创建blob并下载
			saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${tab.name}异常数据.xlsx`);
			this.messageApi("导出成功！");
			this.showDialog = false;
			this.EmptyDevice(true);
		},
		async getData() {
			this.listLoading = true;
			const api = this.tabId === 1 ? geDeviceListApi : ""; // 温度贴/中继器
			const { data, status } = await api();
			if (status && status === 1 && data.length) {
				this.getTemperatureFun(data);
				this.equipSnList = [...data];
			}
			this.listLoading = false;
		},
		async getTemperatureFun(list) {
			const { data, status } = await getTemperature({
				equipSns: list.map(e => e.equipSn)
			});
			if (status && status === 1) {
				this.list = data.filter(v => v.temperature || v.temperature == 0);
				this.setList();
			}
			this.time = setTimeout(() => {
				this.getTemperatureFun(list);
			}, 30000); // 30秒请求一次
		},
		targetTemperature(data) {
			this.temperature = data;
			if (data?.bool) this.setList();
		},
		// 异常数据
		abnormalFun(temperature) {
			const { target, deviation } = this.temperature;
			return (target + deviation) >= temperature && (target - deviation) <= temperature;
		},
		setList() {
			this.list = this.list.map(v => {
				return {
					...v,
					abnormal: !this.abnormalFun(v.temperature),
					id: this.equipSnList.find(i => i.equipSn === v.equipSn).id
				}
			});
		}
	},
	mounted () {
		this.getData();
	},
	beforeDestroy () {
		clearTimeout(this.time); // 清除定时器
	},
};
</script>

<style lang="scss" scoped>
	.home {
		width: 100%;
		height: 100%;
		// padding-top: 78px;
		&_info {
			width: 1280px;
			height: calc(100% - 78px);
			margin: 0 auto;
			padding: 20px 40px;
			&_operation {
				display: flex;
				justify-content: space-between;
				.tab {
					display: flex;
					.tab_item {
						font-size: 16px;
						color: var(--color-444);
						margin-right: 30px;
						font-weight: 400;
						line-height: normal;
						position: relative;
						cursor: pointer;
						&::after {
							content: "";
							width: 0;
							height: 3px;
							display: inline-block;
							border-radius: 3px;
							background: var(--color-main);
							position: absolute;
							bottom: -6px;
							left: 50%;
							transform: translateX(-50%);
							transition: all .3s;
						}
					}
					.tab_item_active {
						color: var(--color-main);
						&::after {
							width: 62px;
						}
					}
				}
				.operation {
					&_button {
						padding: 6px 11px;
						border: none;
						background: var(--color-main);
						color: var(--color-FFF);
						font-size: 14px;
						line-height: normal;
						padding: 6px 15px;
						border-radius: 4px;
						display: inline-flex;
						align-items: center;
						margin-right: 20px;
						vertical-align: middle;
						cursor: pointer;
						&:last-child {
							margin: 0;
						}
						img {
							width: 16px;
							height: 16px;
							margin-right: 10px;
						}
						&:nth-child(1) {
							background: var(--color-FF0);
						}
					}
					.emptyDisabled,
					.emptyDisabled2 {
						background: var(--color-DIS);
						border-color: var(--color-DIS);
						cursor: no-drop;
					}
				}
			}

			&_list {
				width: calc(100% + 6px);
				margin-top: 36px;
				display: flex;
				flex-wrap: wrap;
				height: calc(100% - 68px);
				overflow: auto;
				.list_item {
					width: 183px;
					height: 90px;
					border: 1px solid #E7E7E7;
					border-radius: 4px;
					background: var(--color-FFF);
					box-shadow: 0px 0px 5px 0px rgba(164, 164, 164, 0.20);
					text-align: center;
					margin: 0 20px 20px 0;
					&:nth-child(6n) {
						margin: 0 0 20px 0;
					}
					.code {
						font-size: 14px;
						columns: var(--color-444);
						padding: 13px 0 12px;
					}
					.info {
						color: #56C221;
						.num {
							font-size: 24px;
						}
						.unit {
							font-size: 14px;
						}
					}
					.abnormal {
						color: #F00;
					}
				}
			}
			.home_info_list:hover {
				&::-webkit-scrollbar  {
					width: 6px;     /*高宽分别对应横竖滚动条的尺寸*/
					height: 1px;
				}
			}
			.home_info_list::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
				border-radius: 4px;
				/*-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);*/
				background: #9092984d;
			}

			.home_info_list::-webkit-scrollbar-track { /*滚动条里面轨道*/
				/*-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);*/
				border-radius: 4px;
				background: rgba(0, 0, 0, 0);
			}
		}
	}
</style>

<style lang="scss">
	.dialog_equipment {
		.dialog_content {
			padding-left: 20px;
			.el-form-item__label {
				line-height: 28px;
			}
			.file_info {
				display: flex;
				&_list {
					.operation {
						width: 209px;
						height: 28px;
						border-radius: 4px;
						border: 1px solid #DCDFE6;
						padding: 0 10px;
						display: flex;
						align-items: center;
						line-height: 28px;
						margin-bottom: 14px;
						cursor: pointer;
						.text {
							width: 160px;
							flex-shrink: 0;
						}
						.line {
							width: 1px;
							height: 20px;
							background: #DCDFE6;
						}
						img {
							width: 16px;
							margin-left: 10px;
						}
					}
					.list {
						width: 209px;
						height: 28px;
						border-radius: 4px;
						border: 1px solid #DCDFE6;
						background: #F4F4F5;
						padding: 0 8px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						line-height: 28px;
						.name {
							width: calc(100% - 20px);
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							/* autoprefixer: ignore next */
							-webkit-box-orient: vertical;
							word-break: break-all;  // 不生效，强制换行
							-webkit-line-clamp: 1;
						}
						i {
							cursor: pointer;
							display: none;
						}
						&:hover {
							i {
								display: block;
							}
						}
					}
				}
				&_status {
					line-height: 28px;
					color: var(--color-56C);
					margin-left: 20px;
				}
			}
		}
	}
</style>
