'use strict'
import { TOKEN_NAME } from '@/utils/const';
import axios from 'axios';
import { Message } from 'element-ui';
import router from '@/router';

console.log(process.env, "-------");

let token = '';
let baseUrl = `${ process.env.VUE_APP_API }/gw/v2.0`;
console.log("baseUrl", baseUrl);

axios.interceptors.response.use(response => { // 请求正确
  if (typeof response.data === 'string') {
    response.data = eval("(" + response.data + ")")
	}

  if (response.status === 200 && response.data && response.data.status === 1) {
    return response.data;
  } else if (response.status === 200 && response.data && response.data.status === 9999) {
		router.push('/login');
    return response.data;
  } else {
    if (response.data && response.data.status === 401) {
			router.push('/login');
      token = '';
      window.localStorage.removeItem(TOKEN_NAME);
      return response.data;
    }
    Message.error({
      message: response.data.message,
		});
		throw new Error('接口错误');
  }
}, error => { // 错误/token过期或错误
	const response = error.response;
  if (response.status === 401) {
    location.href = location.pathname;
    token = '';
    window.localStorage.removeItem(TOKEN_NAME);
  } else {
    Message.error({
      message: response.data.message || '网络错误', // 用户名或密码错误
    });
  }
  return Promise.resolve(error.response); // 转为 Promise 对象 
})


function judge(url) {
  if (!token) {
    token = localStorage.getItem(TOKEN_NAME);
  }
}

function post(url, data) {
  judge(url);
  return axios({
    method: 'post',
    url: `${baseUrl}${url}`,
    data: data,
    headers: {
      "token": token,
			'X-Requested-With': 'XMLHttpRequest'
    }
  })
}

function get(url, params) {
  judge(url);
  return axios({
    method: 'get',
    url: `${baseUrl}${url}`,
    params, // get 请求时带的参数
    headers: {
      "token": token,
      'X-Requested-With': 'XMLHttpRequest'
    }
  })
}

function upload(url, data) {
	judge(url);
	return axios({
		method: 'post',
		url: `${baseUrl}${url}`,
		data,
		headers: {
			"token": token,
			'Content-Type': 'multipart/form-data'
		}
	});
}

// 登录
export const loginApi = (data) => post('/lab/v1/wiz/login-password', data);

// 获取设备列表
// export const geDeviceListApi = () => get("/lab/v1/equip/main/list");
export const geDeviceListApi = () => get("/lab/v1/wiz/list");

// 删除设备绑定
export const deleteDeviceApi = data => post("/lab/v1/equip/batch-delete", data);

// 导入设备
export const importDevice = (data) => upload("/lab/v1/wiz/import-equip", data);

// 获取设备当前温度
export const getTemperature = data => post("/lab/v1/equip/current-temperature", data);
