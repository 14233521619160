import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "@/assets/style/mixin.scss";

import {
	Button,
	Form,
	FormItem,
	Input,
	Dialog,
	InputNumber,
	Message,
	Loading
} from 'element-ui';

Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Dialog);
Vue.use(InputNumber);
Vue.prototype.$message = Message;

Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
