<template>
	<div class="header">
		<div class="headerInfo">
			<img class="header_logo" src="../assets/logo.png" alt="">
			<div class="header_right">
				<div class="header_right_temperature">
					<div class="temperature">
						<div class="temperature_info">{{ temperature.target }}℃</div>
						<div class="temperature_deviation">±{{ temperature.deviation }}℃</div>
					</div>
					<div class="setUp" @click="showDialog = true">设置</div>
				</div>
				<div class="header_right_out" @click="out">
					<img src="../assets/Frame.png" alt="">
				</div>
			</div>
		</div>
		<el-dialog
			:title="outStatus ? '确认退出？' : '温度设置'"
			:visible.sync="showDialog"
			width="488px"
			append-to-body
			custom-class="dialog_Temperature"
			:before-close="handleClose">
			<div class="dialog_content">
				<el-form v-show="!outStatus" :model="form" :rules="rules" ref="form" label-width="150px" label-position="left">
					<el-form-item label="目标温度（℃）" prop="target">
						<el-input-number v-model="form.target" :min="-30" :max="60" :step="1"></el-input-number>
					</el-form-item>
					<el-form-item label="温度偏差（±℃）" prop="deviation">
						<el-input-number v-model="form.deviation" :min="0" :max="1" :step="0.1"></el-input-number>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showDialog = false">取消</el-button>
				<el-button type="primary" @click="confirm">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { TOKEN_NAME, TEMPERATURE_NAME } from '@/utils/const';

export default {
	name: "headerCom",
	data() {
		return {
			showDialog: false,
			form: {
				target: 0,
				deviation: 0
			},
			rules: {
				target: [{ required: true, message: '请输入目标温度', trigger: 'blur' }],
				deviation: [{ required: true, message: '温度偏差', trigger: 'blur' }]
			},
			temperature: { target: 0, deviation: 0 },
			outStatus: false
		}
	},
	watch: {
		showDialog(newValue) {
			if (!newValue) {
				this.form = {
					target: this.temperature?.target || 0,
					deviation: this.temperature?.deviation || 0
				};
			}
		}
	},
	methods: {
		handleClose() {
			this.showDialog = false;
		},
		confirm() {
			if (this.outStatus) {
				window.localStorage.removeItem(TOKEN_NAME);
				this.$router.push("/login");
			} else {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						window.localStorage.setItem(TEMPERATURE_NAME, JSON.stringify(this.form));
						this.temperature = { ...this.form };
						this.$message({
							type: "success",
							message: "温度设置成功！"
						});
						this.$emit("changeTemperature", {
							...this.temperature,
							bool: true
						});
						this.handleClose();
					} else {
						return false;
					}
				});
			}
			
		},
		out() {
			this.outStatus = true;
			this.showDialog = true;
		}
	},
	mounted () {
		const temperature = window.localStorage.getItem(TEMPERATURE_NAME);
		if (temperature) {
			this.temperature = JSON.parse(temperature);
			this.form = {
				target: this.temperature.target,
				deviation: this.temperature.deviation
			}
		}
		this.$emit("changeTemperature", this.temperature);
	}
}
</script>

<style lang="scss" scoped>
	.header {
		width: 100%;
		height: 78px;
		background: var(--color-FFF);
		box-shadow: 0 2px 4px 0 rgba(171, 171, 171, 0.25);
		// position: fixed;
		// top: 0;
		// left: 0;
		.headerInfo {
			width: 1280px;
			height: 100%;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 32px;
		}
		&_logo {
			width: 151px;
		}
		&_right {
			display: flex;
			align-items: center;
			&_temperature {
				width: 252px;
				height: 40px;
				border-radius: 4px;
				border: 1px solid var(--color-main);
				display: flex;
				.temperature {
					width: 191px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 16px;
					color: var(--color-444);
					padding: 0 20px;
					position: relative;
					&::after {
						content: "";
						display: inline-block;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 1px;
						height: 26px;
						background: var(--color-main);
					}
					&_info,
					&_deviation {
						width: 60px;
						text-align: center;
					}
				}
				.setUp {
					flex: 1;
					background: var(--color-main);
					color: var(--color-FFF);
					font-size: 14px;
					line-height: 40px;
					text-align: center;
					cursor: pointer;
				}
			}
			&_out {
				width: 16px;
				height: 16px;
				margin-left: 30px;
				cursor: pointer;
				img {
					width: 100%;
				}
			}
		}
		
	}
</style>
<style lang="scss">
	.dialog_Temperature {
		.dialog_content {
			padding-left: 20px;
		}
		.el-input-number {
			width: 223px;
		}
	}
</style>